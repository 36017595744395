import React from 'react'
import Helmet from 'react-helmet'
import styled from "styled-components"
import { rem } from "polished"
import { graphql } from 'gatsby'

import ProjectThumbnail from "../components/Project/projectThumbnail"

const Post = styled.main`
  width: 100%;
  transition: all 0.2s ease-out;
  opacity: 0;
  transform: translateY(-${rem(20)});

  &.loaded {
    opacity: 1;
    transform: none;
  }
`
const ProjectContent = styled.section`
  width: 100%;
  margin-top: ${rem(40)};

  &:first-child {
    margin-top: ${rem(10)};
  }

  article {
    width: 80%;
    margin: 4.5rem auto;
    margin-top: 3rem;
    margin-bottom: 5rem;

    &:first-child {
      margin-top: 0;
    }

    iframe {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 2rem;
    }

    .embed-container {
      margin-bottom: 2rem;
    }

    .row {
      display: flex;
    }
    
    .column {
      flex: 33.33%;
      padding: 5px;
    }

    p {
      font-family: ${props => props.theme.ffSecondary};
      color: ${props => props.theme.colorBlack};
      font-size: 1.8rem;
      line-height: 1.5;
      font-weight: 400;
      font-kerning: normal;
      font-variant-ligatures: contextual;
      font-feature-settings: "dlig";
      margin-bottom: 3.6rem;
    }

    h3 {
      font-family: ${props => props.theme.ffPrimary};
      color: ${props => props.theme.colorBlack};
      font-size: 3rem;
      line-height: 1.1;
      font-weight: 600;
      font-kerning: normal;
      letter-spacing: -0.03em;
      font-variant-ligatures: discretionary-ligatures;
      font-feature-settings: "dlig";
      margin-bottom: 2rem;
      margin-top: 6rem;
    }

    blockquote {
      p {
        font-family: ${props => props.theme.ffSecondary};
        color: ${props => props.theme.colorBlack};
        font-size: 1.8rem;
        line-height: 1.5;
        font-weight: 400;
        font-kerning: normal;
        font-variant-ligatures: discretionary-ligatures;
        font-feature-settings: "dlig";
        margin-bottom: 3rem;
      }
    }
    ul { 
      list-style: disc outside;
      counter-reset: list 0;
      position: relative;
      margin: ${rem(24)};
      font-family: ${props => props.theme.ffSecondary};
      font-size: 1.8rem;
      width: 80%;
    }

    a {
      text-decoration: underline;
      text-decoration-color: ${props => props.theme.colorYellow};
      text-decoration-thickness: 3px;
      transition: color 0.2s ease-out;

      &:hover {
        color: ${props => props.theme.colorBlue};
      }
    }
    .example_c {
      color: #ffffff !important;
      font-family: ${props => props.theme.ffPrimary};
      font-size: 2rem;
      line-height: 1.5;
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      background: #2a6ebb;
      border-radius: 6px;
      padding:  1.5rem;
      border: 4px solid #2a6ebb !important;
      margin:auto;
      margin-bottom: 2rem;
      margin-top: 2rem;
      display:block;
      transition: all 0.2s ease 0s;
      }
      .example_c:hover {
        color: #ffffff !important;
        background: #f2d600;
        border-color: #f2d600 !important;
        transition: all 0.2s ease 0s;
        }


  @media ${props => props.theme.smallUp} {
    margin-top: ${rem(50)};

    &:first-child {
      margin-top: ${rem(20)};
    }
    article {
      max-width: ${rem(660)};
    }

    article p {
      font-size: ${rem(28)};
      margin-bottom: ${rem(28)};
    }

    article h3 {
      font-size: ${rem(42)};
    }

    article blockquote p {
      font-size: ${rem(28)};
      margin-bottom: ${rem(28)};
    }
    article ul { 
      list-style: disc outside;
      counter-reset: list 0;
      position: relative;
      margin: ${rem(28)};
      font-family: ${props => props.theme.ffSecondary};
      font-size: ${rem(28)};
      width: 100%;
    }
  }
  
  @media ${props => props.theme.mediumUp} {
    &:first-child {
      margin-top: ${rem(50)};
    }

    article {
      max-width: ${rem(700)};
    }
    article p {
      font-size: ${rem(32)};
      margin-bottom: ${rem(32)};
    }
   
    article h3 {
      font-size: ${rem(48)};
    }

    article blockquote p {
      font-size: ${rem(32)};
    }
    article ul { 
      list-style: disc outside;
      counter-reset: list 0;
      position: relative;
      margin: ${rem(32)};
      font-family: ${props => props.theme.ffSecondary};
      font-size: ${rem(32)};
      width: 100%;
    }
  }
  @media ${props => props.theme.largeUp} {
    &:first-child {
      margin-top: ${rem(60)};
    }

    article {
      max-width: ${rem(960)};
      margin: ${rem(80)} auto;
      margin-top: 3rem;
      margin-bottom: 5rem;
    }
    article p {
      font-size: ${rem(33)};
      margin-bottom: ${rem(36)};
    }
    article h3 {
      font-size: ${rem(49)};
    }
    article blockquote p {
      font-size: ${rem(33)};
    
    }
    article ul { 
      list-style: disc outside;
      counter-reset: list 0;
      position: relative;
      margin: ${rem(33)};
      font-family: ${props => props.theme.ffSecondary};
      font-size: ${rem(33)};
      width: 100%;
    }
    
  }
}
@media ${props => props.theme.xlargeUp} {
  &:first-child {
    margin-top: ${rem(70)};
  }

  article {
    max-width: ${rem(960)};
    margin: ${rem(35)} auto;
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  article p {
    font-size: 2rem;
  }
  article h3 {
    font-size: 4.5rem;
  }
  article blockquote p {
    font-size: 2rem;
    margin-bottom: 3rem;
  }
  article ul { 
    list-style: disc outside;
    counter-reset: list 0;
    position: relative;
    margin: ${rem(40)};
    font-family: ${props => props.theme.ffSecondary};
    font-size: 2rem;
    width: 100%;
  }
}
  @media ${props => props.theme.xxlargeUp} {
    &:first-child {
      margin-top: ${rem(70)};
    }

    article {
      max-width: ${rem(900)};
      margin: ${rem(120)} auto;
      margin-top: 3rem;
      margin-bottom: 5rem;
    }
    article p {
      font-size: ${rem(40)};
    }
    article h3 {
      font-size: ${rem(60)};
    }
    article blockquote p {
      font-size: ${rem(50)};
    }
    article ul { 
      list-style: disc outside;
      counter-reset: list 0;
      position: relative;
      margin: ${rem(40)};
      font-family: ${props => props.theme.ffSecondary};
      font-size: ${rem(40)};
      width: 100%;
    }
`
const ProjectNavigation = styled.nav`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: calc(100vw + 80px);

  @media ${props => props.theme.mediumUp} {
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: calc(50vw + 100px);
  }

  @media ${props => props.theme.mediumDown} {
    article:first-of-type {
      display: none;
    }
  }
`

const ProjectHeader = styled.header`
  width: 80%;
  margin: 0 auto ${rem(30)} auto;

  @media ${props => props.theme.smallUp} {
    max-width: ${rem(660)};
  }

  @media ${props => props.theme.mediumUp} {
    max-width: ${rem(700)};
  }

  @media ${props => props.theme.largeUp} {
    max-width: ${rem(960)};
  }

  @media ${props => props.theme.xxlargeUp} {
    max-width: ${rem(960)};
  }
  
`

const ProjectTitle = styled.h1`
  font-family: ${props => props.theme.ffPrimary};
  color: ${props => props.theme.colorBlack};
  font-size: 3.5rem;
  line-height: 0.98;
  font-weight: 600;
  font-kerning: normal;
  letter-spacing: -0.05em;
  font-variant-ligatures: discretionary-ligatures;
  font-feature-settings: "dlig";
  margin-bottom: 1.2rem;

  @media ${props => props.theme.smallUp} {
    font-size: 4.5rem;
  }

  @media ${props => props.theme.mediumUp} {
    font-size: 5.5rem;
  }

  @media ${props => props.theme.largeUp} {
    font-size: 6.5rem;
  }

  @media ${props => props.theme.xxlargeUp} {
    font-size: 6.5rem;
  }
`

const ProjectType = styled.h2`
  font-family: ${props => props.theme.ffPrimary};
  color: ${props => props.theme.colorBlack};
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 400;
  font-kerning: normal;
  margin-bottom: 3rem;

  @media ${props => props.theme.smallUp} {
    font-size: 1.4rem;
  }

  @media ${props => props.theme.mediumUp} {
    font-size: 1.6rem;
  }

  @media ${props => props.theme.largeUp} {
    font-size: 1.6rem;
  }

  @media ${props => props.theme.xlargeUp} {
    font-size: 1.8rem;
  }

  @media ${props => props.theme.xxlargeUp} {
    font-size: 1.8rem;
  }
`

class ProjectWrapper extends React.Component {
  constructor() {
    super()

    this.state = {loadState: "loading"}
  }

  componentDidMount() {
    this.setState({loadState: "loaded"})
  }

  componentWillUnmount() {
    this.setState({loadState: "loading"})
  }

  render() {
    return (
      <Post className={this.state.loadState}>
        {this.props.children}
      </Post>
    )
  }
}

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext
}) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark;
  const { next, prev } = pageContext;

  return (
    <div>
      <Helmet title={frontmatter.title + " — Ian Cook Design"} />

      <ProjectWrapper>
        <ProjectContent role="main">
          <ProjectHeader>
            <ProjectTitle>{frontmatter.title}</ProjectTitle>
            <ProjectType>{frontmatter.type}</ProjectType>
          </ProjectHeader>

          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </ProjectContent>

        <ProjectNavigation>
          {prev && (
            <ProjectThumbnail key={prev.id} project={prev} />
          )}
          {next && (
            <ProjectThumbnail key={next.id} project={next} />
          )}
        </ProjectNavigation>
      </ProjectWrapper>
    </div>
  );
}


export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        type
        order
      }
    }
  }
  
`;